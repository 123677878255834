var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page',{scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h2',{staticClass:"page-title"},[_vm._v("People")])]},proxy:true},{key:"page-content",fn:function(){return [_c('b-steps',{attrs:{"animated":true,"rounded":true,"label-position":"bottom","mobile-mode":"minimalist"},scopedSlots:_vm._u([{key:"navigation",fn:function(ref){
var previous = ref.previous;
var next = ref.next;
return [_c('div',{staticClass:"level step-footer"},[_c('div',{staticClass:"level-right"},[_c('b-button',{attrs:{"type":"is-disabled","disabled":previous.disabled},on:{"click":function($event){$event.preventDefault();return previous.action($event)}}},[_vm._v(" Back ")])],1),_c('div',{staticClass:"level-right"},[(
                _vm.data.first_name != null &&
                _vm.form.nik != null &&
                _vm.activeStep === 2
              )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){$event.preventDefault();return _vm.confirm()}}},[_vm._v(" Confirm ")]):_c('b-button',{attrs:{"type":"is-primary","disabled":next.disabled},on:{"click":function($event){$event.preventDefault();return _vm.saveAndNext(next)}}},[_vm._v(" Save & Next ")])],1)])]}}]),model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('b-step-item',{attrs:{"icon":"google-circles-communities","label":"General Information","clickable":_vm.isStepsClickable,"type":_vm.activeStep > 0 ? 'is-success' : ''}},[_c('ValidationObserver',{ref:"form1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitGeneralInformation)}}},[_c('general-information-component',{attrs:{"props-data":_vm.data,"emails":_vm.data.allWorkEmails,"all-emails":_vm.allEmails,"is-edit":true,"list-timezones":_vm.listTimezones},on:{"on-input-typed":_vm.setDataStep1,"on-select-email":_vm.onSelectEmail}})],1)]}}])})],1),_c('b-step-item',{attrs:{"icon":"account-supervisor-circle","label":"Professional Information","clickable":_vm.isStepsClickable,"type":_vm.activeStep > 1 ? 'is-success' : ''}},[_c('ValidationObserver',{ref:"secondStep",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitProfessionalInformation)}}},[_c('proffesional-information-component',{attrs:{"props-form":_vm.form,"employees":_vm.employees,"get-api-employees":_vm.getApiEmployees,"get-selected-employees":_vm.getSelectedEmployees,"get-next-employees":_vm.getNextApiEmployees,"selected-reported-to-info":_vm.selectedReportedToInfo},on:{"clear-reporting-to":_vm.clearReportingTo,"on-input-typed":_vm.setDataStep2}})],1)]}}])})],1),_c('b-step-item',{attrs:{"icon":"file-document","label":"Summary","clickable":_vm.isStepsClickable}},[_c('summary-information-component',{attrs:{"props-data":_vm.data,"props-form":_vm.form}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }