<template>
  <div class="columns is-multiline">
    <div class="column is-6">
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title mb-0">General Information</h3>
        </div>
        <div class="is-12">
          <hr class="black-divider mt-0 mb-3" />
        </div>
        <div class="columns is-multiline">
          <div class="column is-4 ml-3 gray-text-semi-bold">First Name</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.first_name ? data.first_name : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Middle Name</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.middle_name ? data.middle_name : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Last Name</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.last_name ? data.last_name : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Gender</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              data.gender == 'M' ? 'Male' : data.gender == 'F' ? 'Female' : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Religion</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.religion ? data.religion : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">
            Birth Place/Date
          </div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              data.place_of_birth || data.date_of_birth
                ? data.place_of_birth +
                  ', ' +
                  formatDate(data.date_of_birth, 'DD MMMM YYYY')
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Nationality</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.nationality ? data.nationality : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Province</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.province ? data.province : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">City</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.city ? data.city : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">District</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.district ? data.district : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Village</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.village ? data.village : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Address</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.address ? data.address : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">RT/RW</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.rt || data.rw ? data.rt + '/' + data.rw : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Contact Number</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.personal_phone ? data.personal_phone : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Active Admin</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ data.is_admin === '0' ? 'No' : 'Yes' }}
          </div>
        </div>
      </r-card>
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title mb-0">Employment Information</h3>
        </div>
        <div class="is-12">
          <hr class="black-divider mt-0 mb-3" />
        </div>
        <div class="columns is-multiline">
          <div class="column is-4 ml-3 gray-text-semi-bold">
            Reference Number
          </div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ form.nik ? form.nik : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Contract Type</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.contract_type == 'permanent'
                ? 'Permanent'
                : form.contract_type == 'contract'
                ? 'Contract'
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Type of Worker</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.type_of_worker === 'full time'
                ? 'Full Time'
                : form.type_of_worker === 'part time'
                ? 'Part Time'
                : '-'
            }}
          </div>
        </div>
      </r-card>
    </div>
    <div class="column is-6">
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title mb-0">Professional Information</h3>
        </div>
        <div class="is-12">
          <hr class="black-divider mt-0 mb-3" />
        </div>
        <div class="columns is-multiline">
          <div class="column is-4 ml-3 gray-text-semi-bold">Join Date</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.join_date ? formatDate(form.join_date, 'DD MMMM YYYY') : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">
            Probation End Date
          </div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.probation_end_at
                ? formatDate(form.probation_end_at, 'DD MMMM YYYY')
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Contract Date</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.start_contract_at
                ? formatDate(form.start_contract_at, 'DD MMMM YYYY')
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Expired Date</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.end_contract_at
                ? formatDate(form.end_contract_at, 'DD MMMM YYYY')
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Annual Leave</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ form.annual_leave }}
          </div>
        </div>
      </r-card>
      <r-card outlined class="column is-12">
        <div class="column is-12">
          <h3 class="board-title mb-0">Salary Information</h3>
        </div>
        <div class="is-12">
          <hr class="black-divider mt-0 mb-3" />
        </div>
        <div class="columns is-multiline">
          <div class="column is-4 ml-3 gray-text-semi-bold">Basic Salary</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ form.base_salary ? formatCurrency(form.base_salary) : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">
            Probation Salary
          </div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.probation_salary
                ? formatCurrency(form.probation_salary)
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Food Allowance</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ form.eat_allowance ? formatCurrency(form.eat_allowance) : '-' }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">
            Transport Allowance
          </div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.transport_allowance
                ? formatCurrency(form.transport_allowance)
                : '-'
            }}
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">
            Position Allowance
          </div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{
              form.positional_allowance
                ? formatCurrency(form.positional_allowance)
                : '-'
            }}
          </div>
          <div
            v-for="(inputID, index) in form.employment_allowances"
            :key="index"
            class="column is-12"
          >
            <div class="columns is-multiline">
              <div class="column is-4 ml-3 gray-text-semi-bold">
                {{
                  form.employment_allowances[index].allowanceSetting
                    ? form.employment_allowances[index].allowanceSetting.name
                    : ''
                }}
              </div>
              <div class="column is-7 ml-3 has-text-weight-semi-bold">
                {{
                  form.employment_allowances[index].allowanceAmount
                    ? formatCurrency(
                        form.employment_allowances[index].allowanceAmount
                      )
                    : ''
                }}
              </div>
            </div>
          </div>
          <div class="column is-4 ml-3 gray-text-semi-bold">Total Salary</div>
          <div class="column is-7 ml-3 has-text-weight-semi-bold">
            {{ formatCurrency(grandTotal(form)) }}
          </div>
        </div>
      </r-card>
    </div>
  </div>
</template>
<script>
import { formatCurrency, formatDate } from '@/services/util'

export default {
  props: ['propsData', 'propsForm'],
  data() {
    return {
      data: {
        id: null,
        ktp: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        nick_name: '',
        work_email: '',
        personal_email: '',
        personal_phone: '',
        place_of_birth: '',
        date_of_birth: null,
        nationality: '',
        gender: null,
        blood_type: null,
        religion: null,
        password: 'userApp123!',
        address: '',
        rt: '',
        rw: '',
        district: '',
        village: '',
        postal_code: '',
        city: '',
        province: '',
      },

      form: {
        user_id: '',
        nik: '',
        contract_type: '',
        type_of_worker: '',
        join_date: null,
        start_contract_at: null,
        probation_end_at: null,
        end_contract_at: null,
        is_remote: '',
        location_id: '',
        position_id: '',
        reporting_to: '',
        is_active: 0,
        base_salary: 0,
        probation_salary: 0,
        positional_allowance: 0,
        eat_allowance: 0,
        transport_allowance: 0,
        annual_leave: 12,
      },
      isFromCreate: false,
      isSwitchedCustom: false,
      employees: [],
      positions: [],
      work_status: ['permanent', 'contract'],
      type_of_work_status: ['part time', 'full time'],
      locations: [],
      employee_next_page_url: null,
      last_employee_id: null,
      selectedReportedToInfo: null,
    }
  },

  mounted() {
    this.data = this.propsData
    this.form = this.propsForm
  },

  methods: {
    formatCurrency(number) {
      return formatCurrency(number)
    },

    formatDate(date, format) {
      return formatDate(date, format)
    },

    getAdditionalAllowancesTotal() {
      const totalAdditionalAllowances = this.form?.employment_allowances?.reduce(
        (allowanceTotal, allowance) =>
          allowanceTotal + allowance.allowanceAmount,
        0
      )

      return totalAdditionalAllowances
    },

    grandTotal(data) {
      return (
        data.base_salary +
        data.positional_allowance +
        data.eat_allowance +
        data.transport_allowance +
        this.getAdditionalAllowancesTotal()
      )
    },
  },
  watch: {
    handler() {
      this.getSpecificUser()
      this.getSpecificUserEmployment()
    },
  },
}
</script>
